import dayjs from "dayjs"

type DateFormat =
  | "YYYY年MM月DD日"
  | "YYYY年M月D日"
  | "YYYY年MM月DD日 HH:mm"
  | "YYYY年MM月DD日 HH:mm:ss"
  | "YYYY-MM-DD"
  | "YYYY-MM-DD HH:mm"
  | "YYYY-MM-DD HH:mm:ss"
  | "YYYY.MM.DD"
  | "YYYY/MM/DD"
  | "YYYY/MM/DD HH:mm"
  | "YYYY/MM/DD HH:mm:ss"
/**
 * 日付をフォーマットする
 * @param unixtime UNIXタイムスタンプ
 * @param format DateFormatのフォーマット
 * @returns フォーマットされた日付
 */
export const formatUnixTime = (unixtime: number | null | undefined, format: DateFormat): string => {
  return unixtime != null ? dayjs(unixtime).format(format) : ""
}

/**
 * YYYY年MM月DD日 にフォーマットする
 * @deprecated この関数は非推奨です。formatUnixTime を使用してください。
 **/
export const formatDate = (unixtime: number | null | undefined | string): string => {
  return unixtime != null ? dayjs(unixtime).format("YYYY年MM月DD日") : ""
}
/**
 * YYYY年MM月DD日 HH:mm:ss にフォーマットする
 * @deprecated この関数は非推奨です。formatUnixTime を使用してください。
 **/
export const formatDateHour = (unixtime: number | null | undefined): string => {
  return unixtime != null ? dayjs(unixtime).format("YYYY年MM月DD日 HH:mm:ss") : ""
}
/**
 * YYYY-MM-DD HH:mm:ss にフォーマットする
 * @deprecated この関数は非推奨です。formatUnixTime を使用してください。
 **/
export const formatDateHourIso = (unixtime: number | null | undefined): string => {
  return unixtime != null ? dayjs(unixtime).format("YYYY-MM-DD HH:mm:ss") : ""
}
/**
 * YYYY-MM-DD にフォーマットする
 * @deprecated この関数は非推奨です。formatUnixTime を使用してください。
 **/
export const formatDateIso = (unixtime: number | null | undefined): string => {
  return unixtime != null ? dayjs(unixtime).format("YYYY-MM-DD") : ""
}
/**
 * YYYY年MM月DD日 HH:mm にフォーマットする
 * @deprecated この関数は非推奨です。formatUnixTime を使用してください。
 **/
export const formatDateWithoutSeconds = (unixtime: number | null | undefined): string => {
  return unixtime != null ? dayjs(unixtime).format("YYYY年MM月DD日 HH:mm") : ""
}

/**
 * 現在のUnixTimeを取得する
 **/
export const getNowUnixTime = (): number => {
  return dayjs().valueOf()
}
/**
 * 使用期限を当日の23:59:59にする
 * @param expirationDays 期限までの日数。負の値を指定した場合は、現在日の前日の23:59:59の日付が設定されます。
 * @returns {number} 使用期限のUNIXタイムスタンプ（ミリ秒）
 * */
export const getExpirationUnixTime = (expirationDays: number): number => {
  const expirationDate = dayjs().add(expirationDays, "day").endOf("day")
  return expirationDate.unix() * 1000
}

/**
 * 使用期限を指定した日付の23:59:59にする
 * @param expirationDate 期限日
 * @returns {number} 使用期限のUNIXタイムスタンプ（ミリ秒）
 * */
export const mileageExpiredUnixtime = (): number => dayjs().add(1, "year").endOf("month").unix() * 1000
